import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

interface IJsonLd {
  data?: object;
}

export const FdrStructuredData: React.FC<IJsonLd> = ({ data }) =>
  data ? (
    <Head>
      <Script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  ) : null;
