import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrOfferRoomFragmentDoc } from 'fdr/schemas/fragment/room/fdr-room.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrHistoryTravelPeriodFragmentDoc } from 'fdr/schemas/fragment/period/fdr-history-travel-period.generated';
import { FdrHistoryTravellersFragmentDoc } from 'fdr/schemas/fragment/travellers/fdr-history-travellers.generated';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHomeLastActivityQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrHomeLastActivityQuery = {
  __typename?: 'Query';
  fdrHomePage?: {
    __typename?: 'FdrHomePage';
    lastActivity?: {
      __typename?: 'FdrLastActivity';
      items?: Array<
        | {
            __typename?: 'FdrLastActivityOffer';
            offer: {
              __typename?: 'FdrTrackedProductOffer';
              latest?: {
                __typename?: 'FdrProductOffer';
                id: string;
                travelType: Types.FdrProductTravelType;
                departureDate: any;
                returnDate: any;
                duration: number;
                rooms: Array<{
                  __typename?: 'FdrProductOfferRoom';
                  adults: number;
                  childrenDobs?: Array<any> | null;
                  mealDescription: string;
                  roomDescription: string;
                  bookingCode: string;
                  mealType: string;
                  roomType: string;
                }>;
                webMeta: {
                  __typename?: 'FdrSearchResultWebMeta';
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                };
                product?:
                  | {
                      __typename?: 'FdrCruise';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrHotel';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      resort: {
                        __typename?: 'FdrResort';
                        name: string;
                        id: string;
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                          };
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrRoundTrip';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | { __typename?: 'FdrAdventureTravel' }
                  | null;
              } | null;
            };
          }
        | {
            __typename?: 'FdrLastActivitySearch';
            lastSearch: {
              __typename?: 'FdrProductSearchHistory';
              id: string;
              productSearchCriteria?: {
                __typename?: 'FdrHistoryProductSearchCriteria';
                travelType: Types.FdrProductTravelType;
                rooms: number;
                period: {
                  __typename?: 'FdrHistoryTravelPeriodCriteria';
                  returnDate: any;
                  departureDate: any;
                  durations?: Array<number> | null;
                };
                travellers: {
                  __typename?: 'FdrHistoryTravellersCriteria';
                  adults: number;
                  childrenDobs?: Array<any> | null;
                };
                destinations?: {
                  __typename?: 'FdrHistoryTravelDestinationsCriteria';
                  products?: Array<
                    | {
                        __typename?: 'FdrCruise';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrProductWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                          };
                        };
                      }
                    | {
                        __typename?: 'FdrHotel';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrProductWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                        resort: {
                          __typename?: 'FdrResort';
                          name: string;
                          id: string;
                          destination: {
                            __typename?: 'FdrDestination';
                            name: string;
                            id: string;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                            };
                          };
                        };
                      }
                    | {
                        __typename?: 'FdrRoundTrip';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrProductWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                          };
                        };
                      }
                    | { __typename?: 'FdrAdventureTravel' }
                  > | null;
                  geo?: Array<
                    | {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: { __typename?: 'FdrCountry'; name: string };
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                    | {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                    | {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                    | {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                    | {
                        __typename?: 'FdrRegion';
                        name: string;
                        id: string;
                        country: { __typename?: 'FdrCountry'; name: string };
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                    | {
                        __typename?: 'FdrResort';
                        name: string;
                        id: string;
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          country: { __typename?: 'FdrCountry'; name: string };
                        };
                        hero?: {
                          __typename?: 'FdrImage';
                          alt: string;
                          description?: string | null;
                          copyright?: string | null;
                          rawUrl: string;
                          desaturated?: boolean | null;
                          resized: Array<{
                            __typename?: 'FdrResizedImage';
                            id: string;
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          }>;
                        } | null;
                        webMeta: {
                          __typename?: 'FdrGeoWebMeta';
                          offersWebMeta?: {
                            __typename?: 'FdrMeta';
                            link: {
                              __typename?: 'FdrLink';
                              openMethod?: Types.FdrLinkOpenMethod | null;
                              legacyUrl?: string | null;
                              type: Types.FdrLinkType;
                              url: string;
                            };
                          } | null;
                        };
                      }
                  > | null;
                  themes?: Array<{
                    __typename?: 'FdrTheme';
                    name: string;
                    id: string;
                    hero?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }> | null;
                } | null;
              } | null;
              webMeta?: {
                __typename?: 'FdrSearchResultWebMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          }
        | {
            __typename?: 'FdrLastActivityWishlistProduct';
            offer: {
              __typename?: 'FdrTrackedProductOffer';
              latest?: {
                __typename?: 'FdrProductOffer';
                id: string;
                departureDate: any;
                returnDate: any;
                duration: number;
                travelType: Types.FdrProductTravelType;
                rooms: Array<{
                  __typename?: 'FdrProductOfferRoom';
                  adults: number;
                  childrenDobs?: Array<any> | null;
                  mealDescription: string;
                  roomDescription: string;
                  bookingCode: string;
                  mealType: string;
                  roomType: string;
                }>;
                product?:
                  | {
                      __typename?: 'FdrCruise';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrHotel';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      resort: {
                        __typename?: 'FdrResort';
                        name: string;
                        id: string;
                        destination: {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                          };
                        };
                      };
                    }
                  | {
                      __typename?: 'FdrRoundTrip';
                      name: string;
                      id: string;
                      hero?: {
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      } | null;
                      webMeta: {
                        __typename?: 'FdrProductWebMeta';
                        offersWebMeta?: {
                          __typename?: 'FdrMeta';
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        } | null;
                      };
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                        };
                      };
                    }
                  | { __typename?: 'FdrAdventureTravel' }
                  | null;
              } | null;
            };
          }
      > | null;
    } | null;
  } | null;
};

export type FdrLastActivityHotelFragment = {
  __typename?: 'FdrHotel';
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      id: string;
      country: { __typename?: 'FdrCountry'; name: string; id: string };
    };
  };
};

export type FdrLastActivityCruiseFragment = {
  __typename?: 'FdrCruise';
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
};

export type FdrLastActivityRoundtripFragment = {
  __typename?: 'FdrRoundTrip';
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
};

export type FdrProductHeroCriteriaFragment = {
  __typename?: 'FdrImage';
  alt: string;
  description?: string | null;
  copyright?: string | null;
  rawUrl: string;
  desaturated?: boolean | null;
  resized: Array<{
    __typename?: 'FdrResizedImage';
    id: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }>;
};

export type FdrLastActivityDestinationsFragment = {
  __typename?: 'FdrHistoryTravelDestinationsCriteria';
  products?: Array<
    | {
        __typename?: 'FdrCruise';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | {
        __typename?: 'FdrHotel';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
        resort: {
          __typename?: 'FdrResort';
          name: string;
          id: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
        };
      }
    | {
        __typename?: 'FdrRoundTrip';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrProductWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | { __typename?: 'FdrAdventureTravel' }
  > | null;
  geo?: Array<
    | {
        __typename?: 'FdrDestination';
        name: string;
        id: string;
        country: { __typename?: 'FdrCountry'; name: string };
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrCountry';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrContinent';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrCountryGroup';
        name: string;
        id: string;
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrRegion';
        name: string;
        id: string;
        country: { __typename?: 'FdrCountry'; name: string };
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
    | {
        __typename?: 'FdrResort';
        name: string;
        id: string;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          country: { __typename?: 'FdrCountry'; name: string };
        };
        hero?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrGeoWebMeta';
          offersWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      }
  > | null;
  themes?: Array<{
    __typename?: 'FdrTheme';
    name: string;
    id: string;
    hero?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      desaturated?: boolean | null;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
  }> | null;
};

export const FdrProductHeroCriteriaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductHeroCriteria' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'fdrImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resized' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: 'main',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '76' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '100' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrResized' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrLastActivityHotelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrLastActivityHotel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductHeroCriteria' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offersWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductHeroCriteriaFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrLastActivityCruiseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrLastActivityCruise' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCruise' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductHeroCriteria' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offersWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductHeroCriteriaFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrLastActivityRoundtripFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrLastActivityRoundtrip' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRoundTrip' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductHeroCriteria' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offersWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductHeroCriteriaFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrLastActivityDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrLastActivityDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryTravelDestinationsCriteria' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLastActivityHotel' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLastActivityCruise' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrLastActivityRoundtrip',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrContinent' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCountryGroup' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCountry' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRegion' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrDestination' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrResort' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductHeroCriteria',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offersWebMeta' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'fdrLink',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductHeroCriteria' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLastActivityHotelFragmentDoc.definitions,
    ...FdrLastActivityCruiseFragmentDoc.definitions,
    ...FdrLastActivityRoundtripFragmentDoc.definitions,
    ...FdrProductHeroCriteriaFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHomeLastActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHomeLastActivity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FdrLastActivityOffer',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'offer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latest',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'travelType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'departureDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'returnDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'rooms',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrOfferRoom',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'duration',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'webMeta',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'link',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'fdrLink',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'product',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrHotel',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityHotel',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrCruise',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityCruise',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrRoundTrip',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityRoundtrip',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FdrLastActivitySearch',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastSearch' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productSearchCriteria',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'travelType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'rooms',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'period',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'fdrHistoryTravelPeriod',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'travellers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'fdrHistoryTravellers',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'destinations',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'fdrLastActivityDestinations',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'webMeta',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrLink',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FdrLastActivityWishlistProduct',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'offer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latest',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'departureDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'returnDate',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'duration',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'travelType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'rooms',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fdrOfferRoom',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'product',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrHotel',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityHotel',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrCruise',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityCruise',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'FdrRoundTrip',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'fdrLastActivityRoundtrip',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrOfferRoomFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrLastActivityHotelFragmentDoc.definitions,
    ...FdrLastActivityCruiseFragmentDoc.definitions,
    ...FdrLastActivityRoundtripFragmentDoc.definitions,
    ...FdrHistoryTravelPeriodFragmentDoc.definitions,
    ...FdrHistoryTravellersFragmentDoc.definitions,
    ...FdrLastActivityDestinationsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHomeLastActivityQuery__
 *
 * To run a query within a React component, call `useFdrHomeLastActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHomeLastActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHomeLastActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrHomeLastActivityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrHomeLastActivityQuery,
    FdrHomeLastActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHomeLastActivityQuery,
    FdrHomeLastActivityQueryVariables
  >(FdrHomeLastActivityDocument, options);
}
export function useFdrHomeLastActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHomeLastActivityQuery,
    FdrHomeLastActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHomeLastActivityQuery,
    FdrHomeLastActivityQueryVariables
  >(FdrHomeLastActivityDocument, options);
}
export type FdrHomeLastActivityQueryHookResult = ReturnType<
  typeof useFdrHomeLastActivityQuery
>;
export type FdrHomeLastActivityLazyQueryHookResult = ReturnType<
  typeof useFdrHomeLastActivityLazyQuery
>;
export type FdrHomeLastActivityQueryResult = Apollo.QueryResult<
  FdrHomeLastActivityQuery,
  FdrHomeLastActivityQueryVariables
>;
