import React, { ReactElement } from 'react';
import { useFdrPageComponentsContext } from 'fdr/components/local/fdr-page-components';
import { fdrRecommendationProductImageCriteria } from 'fdr/components/util/criterias/recommendation-product-image.dmc';
import { useFdrHomeSmartSeerRecommenderQuery } from 'fdr/schemas/query/home/fdr-home-smart-seer-recommender.generated';
import { FdrSmartSeerRecommenderWrapper } from './fdr-home-content.styles';
import { FdrHomePageComponentsSkeleton } from './fdr-home-page-components.skeleton';

export function FdrHomeSmartSeerRecommender(): ReactElement {
  const context = useFdrPageComponentsContext();

  const { data: smartSeerQuery, loading: smartSeerLoading } =
    useFdrHomeSmartSeerRecommenderQuery({
      variables: {
        ...context,
        productImageCriteria: fdrRecommendationProductImageCriteria,
      },
    });

  if (smartSeerLoading) {
    return <FdrHomePageComponentsSkeleton />;
  }

  return (
    <FdrSmartSeerRecommenderWrapper
      components={[smartSeerQuery?.fdrHomePage?.smartSeerRecommender].filter(
        Boolean
      )}
      isHomeSmartSeerRecommender
    />
  );
}
