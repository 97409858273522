import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrOrganizationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrOrganizationQuery = {
  __typename?: 'Query';
  fdrOrganization?: {
    __typename?: 'FdrOrganization';
    id: string;
    url: string;
    street: string;
    phone: string;
    postalCode: string;
    place: string;
    region: string;
    name: string;
    logo: {
      __typename?: 'FdrImage';
      resized: Array<{
        __typename?: 'FdrResizedImage';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    };
  } | null;
};

export type FdrOrgFragment = {
  __typename?: 'FdrOrganization';
  id: string;
  url: string;
  street: string;
  phone: string;
  postalCode: string;
  place: string;
  region: string;
  name: string;
  logo: {
    __typename?: 'FdrImage';
    resized: Array<{
      __typename?: 'FdrResizedImage';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  };
};

export const FdrOrgFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrOrg' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrOrganization' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'name' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'place' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '350' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '350' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrOrganization' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOrg' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrOrgFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrOrganizationQuery__
 *
 * To run a query within a React component, call `useFdrOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrOrganizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrOrganizationQuery,
    FdrOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrOrganizationQuery,
    FdrOrganizationQueryVariables
  >(FdrOrganizationDocument, options);
}
export function useFdrOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrOrganizationQuery,
    FdrOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrOrganizationQuery,
    FdrOrganizationQueryVariables
  >(FdrOrganizationDocument, options);
}
export type FdrOrganizationQueryHookResult = ReturnType<
  typeof useFdrOrganizationQuery
>;
export type FdrOrganizationLazyQueryHookResult = ReturnType<
  typeof useFdrOrganizationLazyQuery
>;
export type FdrOrganizationQueryResult = Apollo.QueryResult<
  FdrOrganizationQuery,
  FdrOrganizationQueryVariables
>;
