import React from 'react';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { FdrSearchControlFormReset } from 'fdr/components/candidate/fdr-search-control/fdr-search-control-form-reset';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { FdrSearchForm } from 'fdr/components/local/fdr-search-form/fdr-search-form';

interface IHomeSearchFormProps {
  postSubmit?: (state: IFdrSrlControlState) => void;
  className?: string;
}

export const FdrHomeSearchForm: React.FC<IHomeSearchFormProps> = ({
  postSubmit,
  className,
}) => {
  return (
    <FdrSearchForm
      postSubmit={postSubmit}
      formProps={{ className }}
      searchType={ESearchType.HOME}
      enableOnReset
    >
      <FdrSearchControlFormReset />
    </FdrSearchForm>
  );
};
