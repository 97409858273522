import last from 'lodash/last';
import React from 'react';
import {
  DayOfWeek,
  LocalBusiness,
  OpeningHoursSpecification,
  WithContext,
} from 'schema-dts';
import { dayMappings } from '@hotelplan/fdr.lib.misc.schedule';
import { FdrShift, FdrWorkingDay } from '@hotelplan/supergraph-api';
import { FdrAgencyContentQuery } from 'fdr/schemas/query/agency/fdr-agency-content.generated';
import { FdrStructuredData } from './fdr-structured-data';

const HOME_URL = 'https://www.hotelplan.ch';

const schemaDayOfWeek = (
  weekday: string,
  shifts: FdrShift[]
): OpeningHoursSpecification[] => {
  return shifts.map(({ from, to }) => ({
    '@type': 'OpeningHoursSpecification',
    opens: from,
    closes: to,
    dayOfWeek: ('https://schema.org/' +
      weekday.charAt(0) +
      weekday.slice(1).toLowerCase()) as DayOfWeek,
  }));
};

function getFdrAgencyStructuredData<
  A extends FdrAgencyContentQuery['fdrAgency']
>({
  address,
  coordinates,
  email,
  images,
  openingHours,
  phone,
  name,
  webMeta,
}: A): WithContext<LocalBusiness> {
  const resizedImages = images.find(({ alt }) => alt === 'AGT_EXT')?.resized;
  const image = last(resizedImages)?.link.url;

  const openingHoursAsSchema: OpeningHoursSpecification[] = dayMappings.reduce(
    (acc, [weekday, key]) => {
      const shifts: FdrShift[] = (openingHours[key] as FdrWorkingDay)?.shifts;
      if (shifts) {
        return [...acc, ...schemaDayOfWeek(weekday, shifts)];
      }
      return acc;
    },
    []
  );

  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name,
    url: HOME_URL + webMeta.link.url,
    image,
    openingHoursSpecification: openingHoursAsSchema,
    telephone: phone ?? undefined,
    email,
    address: {
      '@type': 'PostalAddress',
      addressLocality: address?.city ?? undefined,
      addressRegion: address?.region ?? undefined,
      postalCode: address?.zip ?? undefined,
      streetAddress: address?.street ?? undefined,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: coordinates?.latitude,
      longitude: coordinates?.longitude,
    },
  };
}

export const FdrAgencyStructuredData = ({
  data,
}: {
  data: FdrAgencyContentQuery['fdrAgency'];
}) => {
  if (!data) return null;

  return <FdrStructuredData data={getFdrAgencyStructuredData(data)} />;
};
