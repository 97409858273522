import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { SkeletonBaseBlock } from 'components/domain/srl-ui/SRLui.styles';
import { FdrMoreVisibleItemContent } from './fdr-home-content.styles';

const ITEM_HEIGHT = 150;
const ITEM_WIDTH = 380;
const ITEMS_COUNT = 3;
const ITEMS_GAP = 20;

const MoreVisibleItem = styled.div({
  marginTop: '16px',
  width: '100%',
  height: '100px',
  borderRadius: '5px',
});

export function FdrHomeMoreVisibleSkeleton(): ReactElement {
  const { mobile } = useDeviceType();

  return mobile ? (
    <div>
      <SkeletonBaseBlock width={'220px'} height={'28px'} borderRadius={'4px'} />
      {Array.from({ length: ITEMS_COUNT }).map((_, i) => (
        <MoreVisibleItem key={i}>
          <div style={{ display: 'flex', height: '100px' }}>
            <SkeletonBaseBlock
              width={'76px'}
              height={'100px'}
              borderRadius={'4px'}
            />
            <FdrMoreVisibleItemContent>
              <SkeletonBaseBlock
                width={`${145 + i * 4}px`}
                height={'24px'}
                borderRadius={'4px'}
              />
              <SkeletonBaseBlock
                style={{ marginTop: '8px' }}
                width={`230px`}
                height={'20px'}
                borderRadius={'4px'}
              />
              <SkeletonBaseBlock
                style={{ marginTop: '8px' }}
                width={`250px`}
                height={'20px'}
                borderRadius={'4px'}
              />
            </FdrMoreVisibleItemContent>
          </div>
        </MoreVisibleItem>
      ))}
    </div>
  ) : (
    <PlaceholderLoader
      uid="home-last-activities"
      width={ITEM_WIDTH * ITEMS_COUNT + ITEMS_GAP * (ITEMS_COUNT - 1)}
      height={ITEM_HEIGHT}
      style={{ width: '100%', height: `${ITEM_HEIGHT}px` }}
    >
      <rect
        x="0"
        y="0"
        width={ITEM_WIDTH * 0.5}
        height={ITEM_HEIGHT * 0.15}
        rx={8}
      />
      <rect x="0" y="40" width={ITEM_WIDTH} height={ITEM_HEIGHT * 0.7} rx={8} />
      <rect
        x={ITEM_WIDTH + ITEMS_GAP}
        y="0"
        width={ITEM_WIDTH * 0.5}
        height={ITEM_HEIGHT * 0.15}
        rx={8}
      />
      <rect
        x={ITEM_WIDTH + ITEMS_GAP}
        y="40"
        width={ITEM_WIDTH}
        height={ITEM_HEIGHT * 0.7}
        rx={8}
      />
      <rect
        x={(ITEM_WIDTH + ITEMS_GAP) * 2}
        y="0"
        width={ITEM_WIDTH * 0.5}
        height={ITEM_HEIGHT * 0.15}
        rx={8}
      />
      <rect
        x={(ITEM_WIDTH + ITEMS_GAP) * 2}
        y="40"
        width={ITEM_WIDTH}
        height={ITEM_HEIGHT * 0.7}
        rx={8}
      />
    </PlaceholderLoader>
  );
}
