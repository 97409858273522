import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';

export const HeroWrapper = styled.div(
  sx2CssThemeFn({
    variant: 'hero.wrap',
    position: 'relative',
    color: 'white',
    bg: 'darkGray',
  })
);

export const HeroImage = styled(FdrImage)<{
  isFullWidth: boolean;
  mainImagePercentageWidth?: [number, number];
}>(({ isFullWidth, mainImagePercentageWidth }) =>
  sx2CssThemeFn({
    objectFit: 'cover',
    width: isFullWidth
      ? '100%'
      : mainImagePercentageWidth
      ? [`${mainImagePercentageWidth[0]}%`, `${mainImagePercentageWidth[1]}%`]
      : ['67%', '60%'],
    height: '100%',
  })
);

export const MacroImage = styled(FdrImage)<{
  mainImagePercentageWidth?: [number, number];
}>(({ mainImagePercentageWidth }) =>
  sx2CssThemeFn({
    objectFit: 'cover',
    width: mainImagePercentageWidth
      ? [
          `${100 - mainImagePercentageWidth[0]}%`,
          `${100 - mainImagePercentageWidth[1]}%`,
        ]
      : ['33%', '40%'],
    height: '100%',
  })
);

export const HeroImageSkeleton = styled.div(({ theme: { colors } }) => ({
  backgroundColor: colors.lightGreySecond,
  width: '100%',
  height: '100%',
}));

export const HeroContent = styled.div(
  sx2CssThemeFn({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    px: 4,
    py: 5,
  })
);

export const HeroTitle = styled.h2(
  sx2CssThemeFn({
    textAlign: 'center',
    fontSize: [5, 6],
  })
);
