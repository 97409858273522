import { TFunction } from 'next-i18next';
import { getShortInformation } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { parseDateStr } from '@hotelplan/libs.utils';
import { FdrHomeLastActivityQuery } from 'fdr/schemas/query/home/fdr-home-last-activity.generated';

export function getLastActivityItemTitleTranslation(
  item: FdrHomeLastActivityQuery['fdrHomePage']['lastActivity']['items'][number],
  currentCount: number | undefined,
  totalCount: number
): string {
  if (currentCount > 1) return '';

  switch (item.__typename) {
    case 'FdrLastActivityOffer':
      if (totalCount > 1)
        return 'home:moreVisibleOffers.itemType.offers.plural';
      return 'home:moreVisibleOffers.itemType.offers';

    case 'FdrLastActivitySearch':
      return 'home:moreVisibleOffers.itemType.searches';
    case 'FdrLastActivityWishlistProduct':
      return 'home:moreVisibleOffers.itemType.wishlist';
  }
}

interface ITravelInformation {
  departureDate: string;
  returnDate: string;
  duration: number | Array<number>;
}

export function getLastActivityTravelInformation(
  t: TFunction,
  { departureDate, returnDate, duration }: ITravelInformation
): string {
  const info = getShortInformation(t, {
    departureDate: departureDate ? parseDateStr(departureDate) : undefined,
    returnDate: returnDate ? parseDateStr(returnDate) : undefined,
    durationData: {
      type: 'night',
      duration: duration
        ? Array.isArray(duration)
          ? duration
          : [duration]
        : [],
    },
  });

  return `${info.travelDates} | ${info.duration}`;
}
