import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import {
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { lCfg, pCfg } from 'fdr/components/util/criterias/base.dmc';

export const fdrHeroMobileImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`375w_mobile`, pCfg(375, 115, FdrImageResizeMode.FillDown)],
  [`414w_mobile`, pCfg(414, 127, FdrImageResizeMode.FillDown)],
  [`768w_mobile`, pCfg(768, 235, FdrImageResizeMode.FillDown)],
  [`912w_mobile`, pCfg(912, 279, FdrImageResizeMode.FillDown)],

  // mobile landscape
  [`740w_mobile`, lCfg(740, 224, FdrImageResizeMode.FillDown)],
  [`915w_mobile`, lCfg(915, 277, FdrImageResizeMode.FillDown)],
  [`1024w_mobile`, lCfg(1024, 310, FdrImageResizeMode.FillDown)],
  [`1368w_mobile`, lCfg(1368, 414, FdrImageResizeMode.FillDown)],
];

const fdrHeroDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`1920w_desktop`, lCfg(1920, 567, FdrImageResizeMode.FillDown)],
  [`2560w_desktop`, lCfg(2560, 756, FdrImageResizeMode.FillDown)],
  [`3440w_desktop`, lCfg(3440, 1016, FdrImageResizeMode.FillDown)],
  [`3840w_desktop`, lCfg(3840, 1134, FdrImageResizeMode.FillDown)],
];

export const fdrHeroImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrHeroMobileImagesSpecs,
  ...fdrHeroDesktopImagesSpecs,
]);
