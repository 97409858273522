import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const HeroSkeletonWrapper = styled.div<{ height: number }>(({ height }) => ({
  height: `${height}px`,
}));

export const FdrHeroSkeleton: React.FC<{
  className?: string;
  height?: number;
}> = ({ className, height }) => {
  const { mobile } = useDeviceType();

  const defaultHeight = mobile ? 185 : 567;

  return (
    <HeroSkeletonWrapper height={height ?? defaultHeight} className={className}>
      <PlaceholderLoader
        uid="hero-skeleton"
        width={100}
        height={100}
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      >
        <rect width="100" height="100" />
      </PlaceholderLoader>
    </HeroSkeletonWrapper>
  );
};
