import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { getShortInformation as getTravellersShortInformation } from '@hotelplan/components.common.travel-rooms';
import { FdrImage, FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  FdrHomeLastActivityQuery,
  useFdrHomeLastActivityQuery,
} from 'fdr/schemas/query/home/fdr-home-last-activity.generated';
import {
  FdrMoreVisibleComponentContainer,
  FdrMoreVisibleComponentWrap,
  FdrMoreVisibleItemCard,
  FdrMoreVisibleItemContent,
  FdrMoreVisibleItemImage,
  FdrMoreVisibleItemTitle,
  FdrMoreVisibleItemWrap,
} from './fdr-home-content.styles';
import { FdrHomeMoreVisibleSkeleton } from './fdr-home-more-visible.skeleton';
import { mapLastActivityLocations } from './fdr-home-more-visible.utils';
import {
  getLastActivityItemTitleTranslation,
  getLastActivityTravelInformation,
} from './fdr-home.utils';
import { useFdrLastActivityItem } from './useFdrLastActivityItem';

interface IFdrHomeMoreVisibleProps {
  pageHero: Omit<FdrImage, 'id'>;
}

export function FdrHomeMoreVisible({
  pageHero,
}: IFdrHomeMoreVisibleProps): ReactElement {
  const [t] = useTranslation(['home', 'results']);

  const { data, loading } = useFdrHomeLastActivityQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <FdrMoreVisibleComponentWrap>
        <FdrMoreVisibleComponentContainer>
          <FdrHomeMoreVisibleSkeleton />
        </FdrMoreVisibleComponentContainer>
      </FdrMoreVisibleComponentWrap>
    );
  }

  const lastActivityItem = data?.fdrHomePage?.lastActivity?.items || [];

  if (!lastActivityItem.length) return null;

  const itemsCount = new Map<string, number>([]);

  return (
    <FdrMoreVisibleComponentWrap>
      <FdrMoreVisibleComponentContainer>
        {lastActivityItem.map((it, idx) => {
          const currentCount = (itemsCount.get(it.__typename) || 0) + 1;
          itemsCount.set(it.__typename, currentCount);

          const totalCount = lastActivityItem.filter(
            i => i.__typename === it.__typename
          ).length;

          return (
            <FdrHomeMoreVisibleItem
              key={idx}
              item={it}
              title={t(
                getLastActivityItemTitleTranslation(
                  it,
                  currentCount,
                  totalCount
                )
              )}
              pageHero={pageHero}
            />
          );
        })}
      </FdrMoreVisibleComponentContainer>
    </FdrMoreVisibleComponentWrap>
  );
}

interface IFdrHomeMoreVisibleItemProps {
  item: FdrHomeLastActivityQuery['fdrHomePage']['lastActivity']['items'][number];
  title: string;
  pageHero: Omit<FdrImage, 'id'>;
}

function FdrHomeMoreVisibleItem({
  item,
  title,
  pageHero,
}: IFdrHomeMoreVisibleItemProps): ReactElement {
  const [t] = useTranslation();

  const lastActivityItemData = useFdrLastActivityItem(item, pageHero);

  if (!lastActivityItemData) return null;

  const travelTypeLabel = t(
    {
      [FdrProductTravelType.Package]: 'common:package.plus',
      [FdrProductTravelType.HotelOnly]: 'common:hotel',
    }[lastActivityItemData.travelType]
  );

  const travellers = lastActivityItemData.travellersInfo
    ? getTravellersShortInformation(t, lastActivityItemData.travellersInfo)
    : null;

  const travelInfoLabel = lastActivityItemData.travelInfo
    ? getLastActivityTravelInformation(t, lastActivityItemData.travelInfo)
    : null;

  const locations = mapLastActivityLocations(lastActivityItemData.locations, t);

  return (
    <FdrMoreVisibleItemWrap>
      {title ? (
        <FdrMoreVisibleItemTitle>{title}</FdrMoreVisibleItemTitle>
      ) : null}
      <FdrMoreVisibleItemCard link={lastActivityItemData.link}>
        <FdrMoreVisibleItemImage image={lastActivityItemData.image} />
        <FdrMoreVisibleItemContent>
          <p className="title">{locations}</p>
          <p>
            {travelTypeLabel}
            {', '}
            {travellers}
          </p>
          <p>{travelInfoLabel}</p>
        </FdrMoreVisibleItemContent>
      </FdrMoreVisibleItemCard>
    </FdrMoreVisibleItemWrap>
  );
}
