import { TFunction } from 'next-i18next';
import { FdrImage } from '@hotelplan/supergraph-api';
import { FdrLastActivityDestinationsFragment } from 'fdr/schemas/query/home/fdr-home-last-activity.generated';
import { FdrHomePageQuery } from 'fdr/schemas/query/home/fdr-home-page.generated';

export interface ILastActivityLocations {
  name?: string;
  count?: number;
}

export const hasLastActivityDestinations = (
  destinations: FdrLastActivityDestinationsFragment
): boolean => {
  if (!destinations) return false;

  for (const value of Object.values(destinations)) {
    if (Array.isArray(value) && value?.length) {
      return true;
    }
  }

  return false;
};

export const mapFdrHomePageLastActivityHeroImageToFdrImage = (
  data: FdrHomePageQuery
): Omit<FdrImage, 'id'> | null => {
  const hero = data?.fdrHomePage?.lastActivityHero;

  if (!hero) return null;

  return { ...hero, resized: hero.lastActivityResized };
};

export const mapLastActivityLocations = (
  locations: string | ILastActivityLocations[],
  t: TFunction
): string => {
  if (Array.isArray(locations)) {
    if (locations.length > 1) {
      return locations.map(val => val.name).join(' / ');
    }

    return locations[0].count
      ? t('home:moreVisibleOffers.destinations.count', {
          count: locations[0].count,
        })
      : locations[0].name;
  }
  return t(locations);
};
