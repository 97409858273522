import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const ResetButton = styled(BsButton)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.BUTTON,
    py: 2,
  })
);

export const ResetIcon = styled(BsIcon)(
  sx2CssThemeFn({
    mr: 2,
    width: '25px',
    height: '25px',
  })
);
