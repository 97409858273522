import type { NextPage } from 'next';
import React from 'react';
import { FdrHomeContent } from 'fdr/components/local/fdr-home';
import { FdrStructuredDataOrganization } from 'fdr/components/local/fdr-structured-data';
import { FdrHotelsVisitHistoryFragment } from 'fdr/schemas/query/history/fdr-hotels-visit-history.generated';

export { getServerSideProps } from 'components/page/home/home.gssp';

interface IHomePageProps {
  lastSeenHotels?: FdrHotelsVisitHistoryFragment[];
}

const HomePage: NextPage<IHomePageProps> = ({ lastSeenHotels }) => {
  return (
    <>
      <FdrStructuredDataOrganization />
      <FdrHomeContent hasLastSeenHotels={!!lastSeenHotels?.length} />
    </>
  );
};

export default HomePage;
