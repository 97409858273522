import { TFunction } from 'next-i18next';
import { FdrSearchType } from '@hotelplan/supergraph-api';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { IFdrTravelDestinationState } from 'fdr/components/candidate/fdr-search/types/search-form.types';

export function searchTypeMapper(searchType: ESearchType): FdrSearchType[] {
  const baseSearchTypes = [
    FdrSearchType.Adventuretravel,
    FdrSearchType.Theme,
    FdrSearchType.Continent,
    FdrSearchType.Country,
    FdrSearchType.Countrygroup,
    FdrSearchType.Cruise,
    FdrSearchType.Destination,
    FdrSearchType.ExtraHotel,
    FdrSearchType.Giata,
    FdrSearchType.Hotel,
    FdrSearchType.Mythos,
    FdrSearchType.Region,
    FdrSearchType.Resort,
    FdrSearchType.Roundtrip,
    FdrSearchType.Theme,
  ];
  const flightSearchTypes = [FdrSearchType.Airport, FdrSearchType.Iata];
  if (searchType === ESearchType.FLIGHT) {
    return flightSearchTypes;
  } else {
    return [...baseSearchTypes, ...flightSearchTypes];
  }
}

export const getShortInformation = (
  t: TFunction,
  items: Array<{ name: string }>,
  limit?: number
): string => {
  const currentLimit = limit ? limit : 2;
  return items.length > currentLimit
    ? t('search:destination.count', { count: items.length })
    : items.map(item => item.name).join(', ');
};

export const getHotelObjectIdFromTravelDestination = (
  state: IFdrTravelDestinationState
) => {
  return state?.ids?.productPublicIds?.length > 0
    ? state.ids.productPublicIds[0]
    : undefined;
};
