import Head from 'next/head';
import Script from 'next/script';
import React from 'react';
import { Organization, WithContext } from 'schema-dts';
import {
  FdrOrgFragment,
  useFdrOrganizationQuery,
} from 'fdr/schemas/query/organization/fdr-organization.generated';

const structuredDataForOrganization = ({
  name,
  url,
  logo: flogo,
  phone,
  place,
  postalCode,
  region,
  street,
}: FdrOrgFragment): WithContext<Organization> => {
  const logo = flogo?.resized?.[0]?.link?.url;

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name,
    url,
    logo,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phone,
      contactType: `customer service`,
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: place,
      addressRegion: region,
      postalCode,
      streetAddress: street,
    },
  };
};

export function FdrStructuredDataOrganization(): React.ReactElement {
  const { data } = useFdrOrganizationQuery();

  if (!data?.fdrOrganization) return null;

  return (
    <Head>
      <Script
        key="org-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            structuredDataForOrganization(data.fdrOrganization)
          ),
        }}
      />
    </Head>
  );
}
