import React, { ReactElement } from 'react';
import { LoginErrorNotification } from '@hotelplan/components.common.auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { trackSearch } from '@hotelplan/libs.tracking';
import { fdrHomeVars } from 'components/page/home/home.vars';
import { FeatureList } from 'config/pageConfig.constants';
import { FdrHero, FdrHeroSkeleton } from 'fdr/components/candidate/fdr-hero';
import { mapSearchControlStateToTrackableData } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import { FdrHolidayFinderTeaser } from 'fdr/components/local/fdr-holiday-finder';
import { mapFdrHomePageLastActivityHeroImageToFdrImage } from 'fdr/components/local/fdr-home/fdr-home-more-visible.utils';
import {
  fdrCommonPageComponentsVariables,
  FdrPageComponents,
  useFdrPageComponentsContext,
} from 'fdr/components/local/fdr-page-components';
import { FdrStyledTextBlock } from 'fdr/components/local/fdr-styled-text-block';
import NoSsr from 'fdr/components/local/no-ssr/no-ssr';
import { useFdrHomePageQuery } from 'fdr/schemas/query/home/fdr-home-page.generated';
import { useFdrHomePageComponentsQuery } from 'fdr/schemas/query/home/page-components/fdr-home-page-components.generated';
import {
  FdrBannerFormWrap,
  FdrHeroContentInner,
  FdrHeroTitle,
  FdrHolidayFinderContestTeaserContainer,
  FdrHomeComponentWrap,
  FdrHomeStaticSection,
} from './fdr-home-content.styles';
import { FdrHomeMoreVisible } from './fdr-home-more-visible';
import { FdrHomePageComponentsSkeleton } from './fdr-home-page-components.skeleton';
import { FdrHomeSearchForm } from './fdr-home-search-form';
import { FdrHomeSmartSeerRecommender } from './fdr-home-smart-seer-recommender';

interface IFdrHomeContentProps {
  hasLastSeenHotels: boolean;
}

export function FdrHomeContent({
  hasLastSeenHotels,
}: IFdrHomeContentProps): ReactElement {
  const { isEnabled } = useFeatureToggle();
  const { mobile } = useDeviceType();

  const isContestTeaserEnabled = isEnabled(
    FeatureList.HOLIDAY_FINDER_CONTEST_TEASER
  );

  const context = useFdrPageComponentsContext();
  const { data: staticData, loading } = useFdrHomePageQuery({
    variables: fdrHomeVars,
  });

  const { data: homeFdrPageComponents, loading: pageComponentsLoading } =
    useFdrHomePageComponentsQuery({
      variables: { ...fdrCommonPageComponentsVariables, ...context },
    });

  const pageComponents =
    homeFdrPageComponents?.fdrHomePage?.pageComponents?.components || [];

  const {
    barColor,
    macro,
    hero,
    title,
    text,
    textBgImage,
    textBgColor,
    textFgColor,
  } = staticData?.fdrHomePage || {};

  return (
    <>
      <FdrBannerFormWrap backgroundColor={barColor}>
        {loading ? (
          <FdrHeroSkeleton />
        ) : (
          <FdrHero macroImage={macro} heroImage={hero}>
            <FdrHeroContentInner>
              {title ? <FdrHeroTitle>{title}</FdrHeroTitle> : null}
            </FdrHeroContentInner>
          </FdrHero>
        )}
        <FdrHomeSearchForm
          postSubmit={state =>
            trackSearch({
              searchControl: mapSearchControlStateToTrackableData(state),
            })
          }
        />
      </FdrBannerFormWrap>
      <FdrHomeMoreVisible
        pageHero={mapFdrHomePageLastActivityHeroImageToFdrImage(staticData)}
      />
      <NoSsr>
        <FdrHomeSmartSeerRecommender />
      </NoSsr>
      <FdrHomeComponentWrap hasLastSeenHotels={hasLastSeenHotels}>
        {isContestTeaserEnabled && !mobile ? (
          <FdrHolidayFinderContestTeaserContainer>
            <FdrHolidayFinderTeaser />
          </FdrHolidayFinderContestTeaserContainer>
        ) : null}
        <FdrHomeStaticSection>
          {staticData && (
            <FdrStyledTextBlock
              title={mobile && title}
              text={text}
              backgroundImage={textBgImage}
              backgroundColor={textBgColor}
              fontColor={textFgColor}
              className="home-static-text"
            />
          )}
          {isContestTeaserEnabled && mobile ? (
            <FdrHolidayFinderContestTeaserContainer>
              <FdrHolidayFinderTeaser />
            </FdrHolidayFinderContestTeaserContainer>
          ) : null}
        </FdrHomeStaticSection>
        {pageComponentsLoading ? (
          <FdrHomePageComponentsSkeleton />
        ) : (
          <FdrPageComponents components={pageComponents} />
        )}
        <LoginErrorNotification />
      </FdrHomeComponentWrap>
    </>
  );
}
