import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { lCfg, pCfg } from 'fdr/components/util/criterias/base.dmc';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const fdrMacroMobileImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`414w_mobile`, pCfg(480, 235, FdrImageResizeMode.FillDown)],
  [`912w_mobile`, pCfg(960, 465, FdrImageResizeMode.FillDown)],

  // mobile landscape
  [`915w_mobile`, lCfg(800, 520, FdrImageResizeMode.FillDown)],
  [`1368w_mobile`, lCfg(1600, 1040, FdrImageResizeMode.FillDown)],
];

const fdrMacroDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`800w_desktop`, lCfg(800, 520, FdrImageResizeMode.FillDown)],
  [`1600w_desktop`, lCfg(1600, 1040, FdrImageResizeMode.FillDown)],
];

export const fdrMacroImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrMacroMobileImagesSpecs,
  ...fdrMacroDesktopImagesSpecs,
]);

export const fdrMacroCriteria = query2FdrCriteria(fdrMacroImagesSpecs);
