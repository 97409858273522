import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Container, {
  ContainerStyles,
} from 'components/domain/container/Container';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrPageComponents } from 'fdr/components/local/fdr-page-components';

export const FdrBannerFormWrap = styled.div<{ backgroundColor?: string }>(
  ({ backgroundColor }) =>
    sx2CssThemeFn({
      pt: [0, '120px'],
      position: [null, 'relative'],
      display: [null, 'flex'],
      flexDirection: [null, 'column'],
      '.tabControls': {
        height: ['50px', '58px'],
      },
      '.hero-content': {
        top: '206px',
        py: 0,
        display: ['none', 'block'],
      },
      backgroundColor: [null, backgroundColor || 'bgLightBlue'],
      '@media screen and (max-width: 891px)': { backgroundColor: 'initial' },
    })
);

export const FdrHomeComponentWrap = styled.div<{
  hasLastSeenHotels: boolean;
}>(({ theme: { space }, hasLastSeenHotels }) =>
  sx2CssThemeFn({
    paddingBottom: [0, hasLastSeenHotels ? '60px' : '100px'],
    marginTop: [0, '50px'],
    zIndex: 1,
    '.dynamic-components > *:first-child': {
      marginTop: space[3],
      '.group-heading': {
        borderTop: 'none',
      },
    },
  })
);

export const FdrHeroContentInner = styled.div(
  sx2CssThemeFn({
    width: '100%',
    position: 'relative',
    maxWidth: '1170px',
    left: '50%',
    transform: 'translateX(-50%)',
  })
);

export const FdrHeroTitle = styled.h1(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    maxWidth: '357px',
    ...FONT_SIZE.XL,
  })
);

export const FdrHolidayFinderContestTeaserContainer = styled.div(
  sx2CssThemeFn({
    px: 4,
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
  })
);

export const FdrHomeStaticSection = styled(Container.withComponent(`section`))(
  sx2CssThemeFn({
    paddingTop: [4, `50px`],
    '.home-static-text': {
      pb: ['40px', '34px'],
      px: [4, 0],
    },
  })
);

export const FdrMoreVisibleComponentWrap = styled.div(
  sx2CssThemeFn({
    backgroundColor: `lightGreySecond`,
    py: 6,
    px: [3, 0],
  })
);

export const FdrMoreVisibleComponentContainer = styled.ul(
  sx2CssThemeFn({
    ...ContainerStyles,
    display: 'flex',
    gap: 3,
    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
  })
);

export const FdrMoreVisibleItemWrap = styled.li(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', '33%'],
    ':first-child h3': {
      pt: 0,
    },
  })
);

export const FdrMoreVisibleItemTitle = styled.h3(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.S,
    mb: [3, 0],
    pt: [4, 0],
    position: ['initial', 'absolute'],
  })
);

export const FdrMoreVisibleItemCard = styled(FdrRoute)(
  ({ theme: { colors } }) =>
    sx2CssThemeFn({
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: colors.white,
      borderRadius: '5px',
      height: '100%',
      mt: [0, '50px'],
      cursor: 'pointer',
      ':hover': {
        color: 'inherit',
      },
    })
);

export const FdrMoreVisibleItemImage = styled(FdrImage)(
  sx2CssThemeFn({
    width: '76px',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '4px',
    flexShrink: '0',
    img: {
      height: '100%',
      width: 'auto',
      maxWidth: 'fit-content',
    },
  })
);

export const FdrMoreVisibleItemContent = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.SMALL,
      padding: 3,
      color: 'darkGray',
      overflow: 'hidden',
      '.title': {
        ...FONT_SIZE.LARGE_BOLD,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'black',
      },
    })
);

export const FdrSmartSeerRecommenderWrapper = styled(FdrPageComponents)(
  sx2CssThemeFn({
    '.group-heading': {
      pt: 0,
      border: 'none',
    },
    '.group-wrapper': {
      pt: ['40px', '50px'],
      pb: 0,
    },
  })
);
