import {
  FdrImage,
  FdrLink,
  FdrProductOfferRoom,
  FdrProductTravelType,
} from '@hotelplan/supergraph-api';
import {
  hasLastActivityDestinations,
  ILastActivityLocations,
} from 'fdr/components/local/fdr-home/fdr-home-more-visible.utils';
import {
  FdrHomeLastActivityQuery,
  FdrLastActivityCruiseFragment,
  FdrLastActivityDestinationsFragment,
  FdrLastActivityHotelFragment,
  FdrLastActivityRoundtripFragment,
} from 'fdr/schemas/query/home/fdr-home-last-activity.generated';

function mapOfferRooms(
  offerRooms: Array<FdrProductOfferRoom>
): ITravellersInfo {
  if (!offerRooms?.length) {
    return null;
  }

  return {
    rooms: offerRooms.length,
    adults: offerRooms.reduce((acc, room) => {
      acc += room.adults;
      return acc;
    }, 0),
    childrenDobs: offerRooms.reduce((acc, room) => {
      if (room?.childrenDobs?.length) {
        return acc.concat(room.childrenDobs);
      }

      return acc;
    }, []),
  };
}

function getProductLocations(
  product:
    | FdrLastActivityHotelFragment
    | FdrLastActivityCruiseFragment
    | FdrLastActivityRoundtripFragment
): Array<ILastActivityLocations> {
  let locations: Array<ILastActivityLocations> = [];

  if (
    product.__typename === 'FdrCruise' ||
    product.__typename === 'FdrRoundTrip'
  ) {
    locations = [
      { name: product.destination.country.name },
      { name: product.destination.name },
    ];
  }

  if (product.__typename === 'FdrHotel') {
    locations = [{ name: product.name }];
  }

  return locations;
}

const getLocationsInFdrLastActivitySearch = (
  destinations: FdrLastActivityDestinationsFragment
): string | ILastActivityLocations[] => {
  if (
    destinations?.products?.[0] &&
    destinations?.products?.[0].__typename !== 'FdrAdventureTravel'
  ) {
    return getProductLocations(destinations?.products?.[0]);
  }

  const destinationsCount =
    (destinations?.geo || []).length + (destinations?.themes || []).length;

  if (destinationsCount > 2) {
    return [
      {
        count: destinationsCount,
      },
    ];
  }

  if (destinations?.geo?.[0]) {
    switch (destinations.geo?.[0]?.__typename) {
      case 'FdrContinent':
      case 'FdrCountryGroup':
      case 'FdrCountry':
      case 'FdrRegion':
      case 'FdrDestination':
      case 'FdrResort': {
        return [
          {
            name: destinations.geo?.[0].name,
          },
        ];
      }

      default:
        return 'home:moreVisibleOffers.allDestinations';
    }
  }

  if (destinations?.themes?.[0]) {
    return [
      {
        name: destinations.themes?.[0].name,
      },
    ];
  }

  return 'home:moreVisibleOffers.allDestinations';
};

interface ITravellersInfo {
  rooms: number;
  adults: number;
  childrenDobs: Array<string>;
}

interface ITravelInfo {
  departureDate: string;
  returnDate: string;
  duration: number | Array<number>;
}

interface IFdrLastActivityItem {
  link: FdrLink;
  image: Omit<FdrImage, 'id'>;
  travelType: FdrProductTravelType;
  travellersInfo: ITravellersInfo;
  travelInfo: ITravelInfo;
  locations: Array<ILastActivityLocations> | string;
}

export function useFdrLastActivityItem(
  item: FdrHomeLastActivityQuery['fdrHomePage']['lastActivity']['items'][number],
  pageHero?: Omit<FdrImage, 'id'>
): IFdrLastActivityItem | null {
  if (!item) return null;

  switch (item.__typename) {
    case 'FdrLastActivityOffer': {
      const product = item.offer?.latest?.product;

      if (!product) return null;

      switch (product.__typename) {
        case 'FdrHotel':
        case 'FdrRoundTrip':
        case 'FdrCruise': {
          return {
            link: product.webMeta?.offersWebMeta.link,
            image: product.hero,
            travelType: item.offer.latest.travelType,
            travellersInfo: mapOfferRooms(item.offer.latest.rooms),
            travelInfo: {
              departureDate: item.offer.latest.departureDate,
              returnDate: item.offer.latest.returnDate,
              duration: item.offer.latest.duration,
            },
            locations: getProductLocations(product),
          };
        }
        default:
          return null;
      }
    }
    case 'FdrLastActivitySearch': {
      const link = item.lastSearch?.webMeta.link;
      const { rooms, travelType, travellers, period, destinations } =
        item?.lastSearch?.productSearchCriteria || {};

      const destProduct = destinations?.products?.[0] as {
        hero: Omit<FdrImage, 'id'>;
      };

      return {
        link,
        image: !hasLastActivityDestinations(destinations)
          ? pageHero
          : destProduct?.hero ||
            destinations?.geo?.[0]?.hero ||
            destinations?.themes?.[0]?.hero,
        travelType: travelType,
        travellersInfo: {
          rooms: rooms || 0,
          adults: travellers?.adults || 0,
          childrenDobs: travellers?.childrenDobs || [],
        },
        travelInfo: {
          departureDate: period?.departureDate || '',
          returnDate: period?.returnDate || '',
          duration: period?.durations
            ? Array.from(
                new Set([
                  period?.durations[0],
                  period?.durations[period?.durations.length - 1],
                ])
              )
            : [],
        },
        locations: getLocationsInFdrLastActivitySearch(destinations),
      };
    }
    case 'FdrLastActivityWishlistProduct': {
      const product = item.offer?.latest?.product;

      if (!product) return null;

      switch (product.__typename) {
        case 'FdrCruise':
        case 'FdrRoundTrip':
        case 'FdrHotel': {
          return {
            link: product.webMeta.offersWebMeta.link,
            image: product.hero,
            travelType: item.offer.latest.travelType,
            travellersInfo: mapOfferRooms(item.offer.latest.rooms),
            travelInfo: {
              departureDate: item.offer.latest.departureDate,
              returnDate: item.offer.latest.returnDate,
              duration: item.offer.latest.duration,
            },
            locations: getProductLocations(product),
          };
        }
        default:
          return null;
      }
    }
  }
}
