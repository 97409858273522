import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { ResetButton, ResetIcon } from './fdr-search-control-form-reset.styles';

export const FdrSearchControlFormReset: React.FC = () => {
  const [t] = useTranslation(['common']);

  const { onReset } = useFormContext();
  const { channelType } = useAuthentication();

  if (channelType !== AuthChannelType.B2B) return null;

  return (
    <ResetButton
      onClick={onReset}
      variant={E_BUTTON_TYPE.LINK_BUTTON}
      data-id="reset-search-control-form-btn"
    >
      <ResetIcon name="app-window-refresh" />
      {t('resetSearchControlForm.button')}
    </ResetButton>
  );
};
