import React from 'react';
import {
  FdrMediaGallerySkeleton,
  HEIGHT_DESKTOP,
  HEIGHT_MOBILE,
} from '@hotelplan/fdr.regular.fusion.fdr-media-gallery';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrRecommendationListSkeleton } from 'fdr/components/local/fdr-recommendation-list/fdr-recommendation-list.skeleton';

export const FdrHomePageComponentsSkeleton = () => {
  const { mobile } = useDeviceType();

  return (
    <>
      <FdrRecommendationListSkeleton hasTitle={true} />
      <FdrRecommendationListSkeleton hasTitle={true} />
      <FdrMediaGallerySkeleton
        uniqueKey="mediaGallerySkeleton"
        height={mobile ? HEIGHT_MOBILE : HEIGHT_DESKTOP}
      />
    </>
  );
};
