import React from 'react';
import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';
import { TFdrBreadcrumb } from '@hotelplan/fdr.regular.fusion.fdr-breadcrumbs';
import { languageDefault } from '@hotelplan/libs.language';
import { FdrStructuredData } from './fdr-structured-data';

const HOME_URL = 'https://www.hotelplan.ch';

function getFdrGeoStructuredData(
  breadCrumbItems: Array<TFdrBreadcrumb> | undefined,
  language?: string
): WithContext<BreadcrumbList> {
  const rootJsonBreadCrumb: ListItem = {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@type': 'WebPage',
      '@id':
        (HOME_URL as string) +
        (language === languageDefault ? '' : `/${language}/`),
      name: 'Home',
    },
  };

  const jsonLdBreadcrumbs: ListItem[] =
    breadCrumbItems?.map(({ label, link }, idx) => ({
      '@type': 'ListItem',
      position: idx + 2,
      item: {
        '@type': 'WebPage',
        '@id': !link ? HOME_URL : HOME_URL + link.url,
        name: label,
      },
    })) || [];

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [rootJsonBreadCrumb, ...jsonLdBreadcrumbs],
  };
}

interface IFdrGeoStructuredDataProps {
  breadcrumbs: Array<TFdrBreadcrumb>;
  language: string;
}

export function FdrGeoStructuredData({
  breadcrumbs,
  language,
}: IFdrGeoStructuredDataProps) {
  return (
    <FdrStructuredData data={getFdrGeoStructuredData(breadcrumbs, language)} />
  );
}
