import React from 'react';
import { IFdrImageProps } from '@hotelplan/fdr.regular.basis.fdr-image';
import {
  FdrPicture,
  FdrPicturePreload,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { FdrImage } from '@hotelplan/supergraph-api';
import {
  HeroContent,
  HeroImage,
  HeroImageSkeleton,
  HeroTitle,
  HeroWrapper,
  MacroImage,
} from 'fdr/components/candidate/fdr-hero/fdr-hero.styles';
import { fdrHeroImagesSpecs } from 'fdr/components/util/criterias/hero.dmc';

interface IHeroProps extends React.PropsWithChildren<{}> {
  title?: string;
  macroImage?: Omit<FdrImage, 'id'>;
  mainImagePercentageWidth?: [number, number];
  className?: string;
  heroImage?: Omit<FdrImage, 'id'>;
}

const imageDefaults = {
  lazy: false,
  decoding: `sync` as IFdrImageProps['decoding'],
  loading: `eager` as IFdrImageProps['loading'],
};

export const FdrHero: React.FC<IHeroProps> = props => {
  const {
    title,
    children,
    macroImage,
    mainImagePercentageWidth,
    className,
    heroImage,
  } = props;

  return (
    <HeroWrapper className={className}>
      {heroImage && (
        <FdrPicturePreload
          sources={fdrHeroImagesSpecs}
          resized={heroImage.resized}
        />
      )}
      {heroImage ? (
        <>
          {macroImage ? (
            <MacroImage
              {...imageDefaults}
              image={macroImage}
              mainImagePercentageWidth={mainImagePercentageWidth}
            />
          ) : null}
          <FdrPicture sources={fdrHeroImagesSpecs} sizes={heroImage.resized}>
            <HeroImage
              {...imageDefaults}
              image={heroImage}
              isFullWidth={!macroImage}
              mainImagePercentageWidth={mainImagePercentageWidth}
            />
          </FdrPicture>
        </>
      ) : (
        <HeroImageSkeleton />
      )}
      <HeroContent className="hero-content">
        {title && <HeroTitle>{title}</HeroTitle>}
        {children && <>{children}</>}
      </HeroContent>
    </HeroWrapper>
  );
};
